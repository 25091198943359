import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import * as Page from '../ui-components/page';
import * as Article from '../ui-components/articles';
import * as Form from '../ui-components/form';
import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';
const guide = () => (
  <Layout>
    <SEO
      title="Sign-Up for a NoStack Account"
      keywords={[`NoStack`, `no-stack`, `gatsby`, `javascript`, `react`]}
    />

    <Page.PageHeader>
      <Article.ArticleTitle>NoStack Front End Guidelines</Article.ArticleTitle>
    </Page.PageHeader>

    <Grid.Container>
      <Grid.Row>
        <Page.PageMessage style={{ fontSize: '1em', lineHeight: '1.6' }}>
          <p>
            The goal of the NoStack project is that changes to the backend will
            only minimally affect the front end. Therefore, it is essential that
            you follow certain rules for any front end code.
          </p>

          <p>
            Any NoStack app code should be checked using the{' '}
            <a href="https://www.npmjs.com/package/ns-front">ns-front CLI</a> to
            ensure that these guidelines have been followed.
          </p>
          <h2 style={{ fontSize: '1.5em' }}>Autogenerated NoStack Code</h2>
          <p>
            A generated, unstyled NoStack React app contains a directory
            src/components with generated components. The components are each
            given a separate directory, grouped within unit directories. At
            times, this code must be regenerated. Your changes need to follow
            the guidelines below to be preserved even after a regeneration. If
            you see a problem with doing so, please contact us at{' '}
            <a href="mailto:info@pivotate.com">info@pivotate.com</a> with any
            issues or requests.
          </p>

          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>
            Making Custom Changes
          </h2>
          <p>The following types of changes are permitted:</p>
          <ol
            style={{
              listStyleType: 'decimal',
              paddingLeft: '50px',
              lineHeight: '0.5',
            }}
          >
            <li>
              <p>Custom Added Code</p>
            </li>
            <li>
              <p>Replacement Code</p>
            </li>
            <li>
              <p>Custom New Components or Functions</p>
            </li>
            <li>
              <p>Removed Code</p>
            </li>
            <li>
              <p>Custom State</p>
            </li>
          </ol>

          <p>Each type contains a section of explanation below.</p>
          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>
            Custom Added Code
          </h2>
          <p>
            You may insert any code that your app requires within designated
            locations. A custom code location is currently specified with
            commented out delimiters at the beginning and end, as follows:
          </p>

          <p>
            // ns__custom_start unit: &lt;unit name&gt; comp: &lt;component
            name&gt; loc: &lt;location name&gt; &#8230; custom code inserted
            here&#8230; // ns__custom_end unit: &lt;unit name&gt; comp:
            &lt;component name&gt; loc: &lt;location name&gt;
          </p>

          <p>
            That code should be replaced completely after a generation, and is
            ideal. If you feel a need to add custom code somewhere else in a
            file, contact{' '}
            <a href="mailto:info@pivotate.com">info@pivotate.com</a> and specify
            the file and location, showing the code that you want to add. We are
            actively adding the custom locations currently.
          </p>

          <p>
            Please understand that code outside of the custom locations will not
            persevere through a regeneration of the code.
          </p>

          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>
            Replacement Code
          </h2>
          <p>
            The files contain several sections which can be replaced by your own
            code. They are currently designated as follows:
          </p>

          <p>
            // ns__start_section &lt;location name&gt; &#8230; replaceable
            here&#8230; // ns__end_section &lt;location name&gt;
          </p>

          <p>
            Please note that if anything on the backend that is relevant to
            replaced code changes, then the code might be broken. So it&#8217;s
            always preferred to add custom code rather than replacement code.
            But, often replacement code will be necessary somewhere in an app.
            To replace a section, change the commented out delimiters as
            follows, and then your changes to the code within will persist:
          </p>
          <p>
            // ns__start_replacement &lt;location name&gt; &#8230; replaceable
            here&#8230; // ns__end_replacement &lt;location name&gt;
          </p>
          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>
            Custom New Components or Functions
          </h2>
          <p>
            There is a designated folder, src/custom, for your own code. You can
            do whatever you&#8217;d like there. Just realize that the more you
            change from generated code the more fragile the changes will be, so
            the general rule is that it&#8217;s better to stick to the generated
            components if you can.
          </p>
          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>Removed Code</h2>
          <p>
            So far, only import statements can be removed. To remove an import
            statement, such as
          </p>

          <p>import styled &#8230;</p>

          <p>simply place &#8220;// ns__remove_&#8221; in front of it, e.g.</p>

          <p>// ns__remove_import styled &#8230;</p>
          <h2 style={{ fontSize: '1.5em', marginTop: '2em' }}>Custom State</h2>
          <p>
            Each unit contains a context, which contains a &#8220;custom&#8221;
            object. You can modify that custom object as needed for your app in
            all of the designated custom code areas as specified above.
          </p>
        </Page.PageMessage>
      </Grid.Row>
    </Grid.Container>
  </Layout>
);

export default guide;
